import { escapeName, scrollWindowTop } from 'adel-shared/dist/utils/functions';
import _ from 'lodash';
import React, {
	ForwardRefExoticComponent,
	PropsWithoutRef,
	RefAttributes,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useReducer,
	useState,
	useContext
} from 'react';
import { RouteComponentProps } from '@reach/router';
import { AdelVersementClient } from '../../../../clients/AdelVersementClient';
import { useAxios } from '../../../../custom-hooks/useAxios';
import { Dictionary } from '../../../../models';
import {
	ArtisteDto,
	ArtistesValidationErrorDto,
	CommissionDto,
	DemandeVersementContratCachetDto,
	DocumentWithValidationDto,
	DossierClient,
	UpdateDepenseDto,
	VersementArtisteDto,
	VersementClient
} from '../../../../services/generated/FrontOffice-api';
import ArtisteList from './artistes/ArtisteList';
import { CategorieDossier } from '../../../../enums/Dossiers';
import ModalValidationArtiste from '../../../dossiers/creationDossier/artistes/ModalValidationArtiste';
import ModalAddArtist from './artistes/ModalAddArtist';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../../../context/AppContext';
import { useForm } from 'react-hook-form';
export interface DocumentDepenses {
	id?:string;
	fileName?:string;
	partName?:string;
	data?:any;
}

interface ContratsCachetsProps extends RouteComponentProps {
	setEditMode: (value: boolean) => void;
	setIsFormValid: (value: boolean) => void;
	setCanGoNext: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
	canGoNext: boolean;
	isLoading: boolean;
	codeCategorie?: CategorieDossier;
}
interface ContratsCachetsActionPayload {
	artisteId?: string;
	artiste?: VersementArtisteDto;
	artisteDocument?: {
		type: 'bulletin' | 'contrat',
		file?: DocumentWithValidationDto;
	};
	contratsCachets?: DemandeVersementContratCachetDto;
}

export interface ContratsCachetsAction {
	type: string;
	payload: ContratsCachetsActionPayload;
}

function contratsCachetsReducer(state: DemandeVersementContratCachetDto, action: ContratsCachetsAction): DemandeVersementContratCachetDto {
	switch(action.type) {
		case 'setAll': 
			const updatedCachets = _.merge({}, state, action.payload.contratsCachets);
			return {
				...updatedCachets,
				totalRemunerationBrut: updatedCachets.artistes.reduce((acc: number, curr) => {
					return acc + parseFloat(curr.totalSalairesBruts.toString());
				}, 0),
				totalChargePatronales: updatedCachets.artistes.reduce((acc: number, curr) => {
					return acc + parseFloat(curr.totalCharges.toString());
				}, 0)
			}

		case 'addArtiste': {
			const updatedArtistes = [
				...state.artistes,
				action.payload.artiste
			];
			return {
				...state,
				artistes: updatedArtistes,
				totalRemunerationBrut: updatedArtistes.reduce((acc: number, curr) => {
					return acc + parseFloat(curr.totalSalairesBruts.toString());
				}, 0),
				totalChargePatronales: updatedArtistes.reduce((acc: number, curr) => {
					return acc + parseFloat(curr.totalCharges.toString());
				}, 0)
			};
		}

		case 'deleteArtiste': {
			const updatedArtistes = state.artistes.filter(a => a.id !== action.payload.artisteId);
			// const index = state.artistes.findIndex(field => field.id === action.payload.artisteId);
   			//  if (index !== -1) {
			// // Unregister the field
			// 	unregister(`${action.payload.artisteId}`); // Adjust this according to your actual field names

			//  }
			return {
				...state,
				artistes: updatedArtistes,
				totalRemunerationBrut: updatedArtistes.reduce((acc: number, curr) => {
					return acc + parseFloat(curr.totalSalairesBruts.toString());
				}, 0),
				totalChargePatronales: updatedArtistes.reduce((acc: number, curr) => {
					return acc + parseFloat(curr.totalCharges.toString());
				}, 0)
			};
		}

		case 'updateArtisteDocument':
			return {
				...state,
				artistes: state.artistes.reduce((acc: VersementArtisteDto[], curr) => {
					if(curr.id === action.payload.artisteId) {
						if(action.payload.artisteDocument?.file) {
							curr[action.payload.artisteDocument.type] = action.payload.artisteDocument.file;
						} else {
							if(curr[action.payload.artisteDocument.type]) {
								delete curr[action.payload.artisteDocument.type];
							}
						}
					}
					acc.push(curr);
					return acc;
				}, [])
			};
	
		case 'updateArtisteDetails': {
			const updatedArtistes = state.artistes.reduce((acc: VersementArtisteDto[], curr) => {
				if(curr.id === action.payload.artiste.id) {
					acc.push({
						...curr,
						...action.payload.artiste
					});
				} else {
					acc.push(curr);
				}
				return acc;
			}, []);

			return {
				...state,
				artistes: updatedArtistes,
				totalRemunerationBrut: updatedArtistes.reduce((acc: number, curr) => {
					return acc + parseFloat(curr.totalSalairesBruts.toString());
				}, 0),
				totalChargePatronales: updatedArtistes.reduce((acc: number, curr) => {
					return acc + parseFloat(curr.totalCharges.toString());
				}, 0)
			};
		}
		default:
			return state;
	}
}

const ContratsCachets: ForwardRefExoticComponent<PropsWithoutRef<ContratsCachetsProps> & RefAttributes<any>> = forwardRef(({
	setIsLoading,
	setIsFormValid,
	codeCategorie,
	setCanGoNext,
	canGoNext,
	isLoading
}, ref) => {
	const axiosInstance = useAxios();
	const dossierClient = new DossierClient('', axiosInstance);
	const versementClient = useMemo(() => { return new VersementClient("", axiosInstance) }, [axiosInstance]);
	const adelVersementClient = useMemo(() => { return new AdelVersementClient("", axiosInstance) }, [axiosInstance]);
	const [{currentEditingVersement: versement}] = useContext(AppStateContext);
	const {t} = useTranslation();

	const form = useForm();
	const { triggerValidation, unregister } = form;

	const [contratsCachet, dispatchContratsCachet] = useReducer(contratsCachetsReducer, {});

	const [artisteSelected, setArtisteSelected] = useState<ArtisteDto>({});
	const [isDuplicatingArtist, setDuplicatingArtist] = useState<boolean>(false);

	const [errorsValidation, setErrorsValidation] = useState<ArtistesValidationErrorDto[]>([]);
	const [modalValidationArtisteIsOpen, setModalValidationArtisteIsOpen] = useState<boolean>(false);

	const [isOpenAddArtiste, openAddArtiste, closeAddArtiste] = useBooleanState();

	// Dépenses
	const [depensesSpecifiques, setDepensesSpecifiques] = useState<UpdateDepenseDto[]>();
	const [depensesAutres, setDepensesAutres] = useState<UpdateDepenseDto[]>();
	const [depensesDocuments, setDepensesDocuments] = useState<DocumentDepenses[]>([]);
	const [artistesDocumentsFiles, setArtistesDocumentsFiles] = useState<Dictionary<File>>({});
	const [commentGeneral, setCommentGeneral] = useState<string>("");
	const [isCommentMandatory, setIsCommentMandatory] = useState<boolean>(false);
	const [validationRequired, setValidationRequired] = useState<boolean>(true)
   
	const [dossierCommission, setDossierCommission] = useState<CommissionDto>({});
	
	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchArtists = useMemo(() => _.debounce(() => {
		versementClient.getContratsCachetsOnDemandeVersement(versement.id).then(result => {
			dispatchContratsCachet({
				type: 'setAll',
				payload: {
					contratsCachets: result
				}
			});
			if (codeCategorie === CategorieDossier.InteretGeneral || codeCategorie === CategorieDossier.AideSpecifique) {
				setDepensesAutres(result.depenses);
			} else {
				setDepensesSpecifiques(result.depenses);
			}
			setCommentGeneral(result.commentaire);
		});
	}, 0), []);

	useEffect(() => {
		scrollWindowTop();
		debounceFetchArtists();
		setCanGoNext(true);

		(async() => {
			const result = await dossierClient.getDossierCommission(versement.originalDossierId);
			setDossierCommission(result);
		})();
	}, []);

	useEffect(() => {
		const montantRealise = depensesSpecifiques?.reduce((acc, curr) => acc + curr.montantRealise, 0);

		setIsCommentMandatory(
			(codeCategorie === CategorieDossier.Festival || codeCategorie === CategorieDossier.SpectacleMusical) &&
			contratsCachet?.totalMontantPrevisionnel < (montantRealise * 0.9)
		);
	}, [
		depensesSpecifiques,
		setIsCommentMandatory
	]);

	useEffect(() => {
		if (errorsValidation && errorsValidation.length > 0) {
			setModalValidationArtisteIsOpen(true)
		}
	}, [errorsValidation])

	const submitForm = async(validationNotRequired?: boolean) => {
		const isValid = await triggerValidation();
			if(isValid || validationNotRequired) {
				// Check des dépenses
				const hasNanDepenseSpe = depensesSpecifiques?.some(d => typeof d.montantRealise !== 'undefined' && isNaN(d.montantRealise));
				const hasNanDepensesAutres = depensesAutres?.some(d => typeof d.montantRealise !== 'undefined' && isNaN(d.montantRealise));
				if(hasNanDepensesAutres || hasNanDepenseSpe) {
					setIsFormValid(false);
					return false;
				}

				if (isCommentMandatory && (!commentGeneral || commentGeneral === "")) {
					return false;
				}	

				const artistesDocs = contratsCachet?.artistes?.filter(x =>  artistesDocumentsFiles[`${escapeName(x.bulletin?.fileName)}-${x.id}`] || artistesDocumentsFiles[`${escapeName(x.contrat?.fileName)}-${x.id}`] || x.bulletin?.actionType == 'delete' || x.contrat?.actionType == 'delete').map(x => {
					if(x.bulletin) {
						return {
							id: x.id,
							bulletin: {
								partName: x.bulletin.fileName ? `${escapeName(x.bulletin.fileName)}-${x.id}` : "",
								actionType: x.bulletin.actionType
							}
						}
					}
					return {
						id: x.id,
						contrat: {
							partName: x.contrat.fileName ? `${escapeName(x.contrat.fileName)}-${x.id}`: "",
							actionType: x.contrat.actionType
						}
					}
					
				});

				setIsLoading(true);
				 
				try {
					await adelVersementClient.updateArtisteDocument(versement.id, artistesDocs, artistesDocumentsFiles);
					
					const depensesDocs: Dictionary<File> = {};
					depensesDocuments.forEach(d => {
						depensesDocs[d.partName] = d.data;
					});
					await adelVersementClient.createOrUpdateDepenses(versement.dossierId, depensesSpecifiques, depensesAutres, depensesDocs);
					await versementClient.updateVersementCommentaire(versement.id, {commentaire: commentGeneral});
					setValidationRequired(true)
					return true;
				} catch(error) {
					if (error.exception?.message)
						toast.error(error.exception.message);
					else
						toast.error(t("errors.default"));
					return false
				} finally {
					setIsLoading(false);
				}
			}
			return false;
	}
	useImperativeHandle(ref, () => ({
		async validateForm() {			
			return submitForm(true)
		}
	}));

    const handleSubmit = async(validationNotRequired?: boolean) => {
        if (validationNotRequired) {
			await setValidationRequired(false)
		}
		if (await submitForm(true)) {
			toast.success(t('common.success'));
		}else{
			toast.error(t("common.validation-error.message"));
		}
	}
	const duplicateArtiste = (id: string) => {
		setDuplicatingArtist(true);
		setIsLoading(true);
		openAddArtiste();
		versementClient.getVersementArtiste(id).then(result => {
			setArtisteSelected({
				...result,
				nom: '',
				prenom: '',
				email: '',
				lieuHabitation: ''
			});
			setIsLoading(false);
		})
	}

	const editArtiste = (id: string) => {
		setIsLoading(true);
		versementClient.getVersementArtiste(id).then(result => {
			setArtisteSelected(result);
			openAddArtiste();
			setIsLoading(false);
		})
	}

	const deleteArtiste = (id: string) => {
		setIsLoading(true);
		unregister(`${id}`);
		dispatchContratsCachet({
			type: 'deleteArtiste',
			payload: {
				artisteId: id
			}
		});
		unregister(id);
		setIsLoading(false);
	}

	const handleUpdateDocuments = (documents: Dictionary<File>) => {
		setArtistesDocumentsFiles(documents);
	};

	const handleCloseModalArtist = () => {
		setDuplicatingArtist(false);
		closeAddArtiste();
	};

	return (
		<>
			<ArtisteList
				contratsCachets={contratsCachet}
				onAddArtist={() => {
					setArtisteSelected({});
					openAddArtiste();
				}}
				duplicateArtiste={duplicateArtiste}
				editArtiste={editArtiste}
				informDeleteArtiste={deleteArtiste}
				depensesSpe={depensesSpecifiques}
				autreDepenses={depensesAutres}
				depensesDocuments={depensesDocuments}
				setDepensesSpecifiques={setDepensesSpecifiques}
				setDepensesAutres={setDepensesAutres}
				setDepensesDocuments={setDepensesDocuments}
				onUpdateDocuments={handleUpdateDocuments}
				artistesDocumentsFiles={artistesDocumentsFiles}
				onUpdateContratsCachets={dispatchContratsCachet}
				commentGeneral={commentGeneral}
				setCommentGeneral={setCommentGeneral}
				isCommentMandatory={isCommentMandatory}
				form={form}				
				canGoNext={canGoNext}
				isLoading={isLoading}
				handleSubmit={handleSubmit}
				validationRequired={validationRequired}
				setValidationRequired={setValidationRequired}
			/>

			{isOpenAddArtiste && (
				<ModalAddArtist
					isOpen={isOpenAddArtiste}
					isDuplicating={isDuplicatingArtist}
					onClose={handleCloseModalArtist}
					onUpdateContratsCachets={dispatchContratsCachet}
					artisteSelected={artisteSelected}
					codeCategorie={codeCategorie}
					commission={dossierCommission}
				/>
			)}

			{errorsValidation.length > 0 &&
				<ModalValidationArtiste
					isOpen={modalValidationArtisteIsOpen}
					onCancel={() => setModalValidationArtisteIsOpen(false)}
					errorsValidation={errorsValidation}
				/>
			}
		</>);
});

export default ContratsCachets;