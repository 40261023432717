import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import InputRadioYesNo from 'adel-shared/dist/components/basics/InputRadioYesNo';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { RouteComponentProps } from '@reach/router';
import { useAxios } from '../../../../custom-hooks/useAxios';
import { DemandeVersementInfosDto, EtapeDemandeVersement, VersementClient } from '../../../../services/generated/FrontOffice-api';
import ModalValidateCancel from '../../../basics/ModalValidateCancel';
import Loader from 'react-loader-spinner';
import { formatNumber, formatPhone } from 'adel-shared/dist/utils/functions';

interface InformationsDossierProps extends RouteComponentProps {
	ref: any;
	versementId: string;
	dossierId: string;
	setCanGoNext: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
	isLoading?: boolean;
	setCurrentStep: (value: EtapeDemandeVersement) => void;
}

const InformationsDossier: React.FunctionComponent<InformationsDossierProps> = forwardRef(({ setCanGoNext, setIsLoading, isLoading, dossierId, setCurrentStep }, ref) => {
	const { t } = useTranslation();

	const axiosInstance = useAxios();
	const versementClient = new VersementClient("", axiosInstance);
	const { triggerValidation } = useForm<any>({ defaultValues: {hasSendDocPromoImage: false}});

	const [infos, setInfos] = useState<DemandeVersementInfosDto>({});
	const [isValidateModalOpen, setIsValidateModalOpen] = useState<boolean>(false);


	/** Get Infos Dossier */
	const getInfos = async () => {
		try {
			var infosVersement = await versementClient.getDemandeVersementInfos(dossierId);
			infosVersement = {...infosVersement, documentAPI : 
				{...infosVersement?.documentAPI,
					name: infosVersement?.documentAPI?.fileName
			 }};
			setInfos(infosVersement);
			setFile(infosVersement?.documentAPI);
		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
	}

	// useEffect(() => {
	// 	if(infos?.documentAPI){
	// 		setFile(infos.documentAPI)
	// 	}
	// },[infos?.documentAPI]);

	useEffect(() => {
		(async () => await getInfos())();
		setCanGoNext(true);
	}, []);

	const [file, setFile] = useState(null);
	/** Post */
	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			if (infos?.isApi) {
				const result = await triggerValidation();
				if (result) {
					if (file == undefined || file?.name == undefined) {
						setIsValidateModalOpen(true);
						return false;
					}
				} else {
					toast.error(t("validation-messages.invalid-form"));
				}
				onValidate();
				return true;
			} else {
				return true;
			}
		}
	}));

	const sendValidate = async () => {

		setIsLoading(true);
		try {
			await versementClient.updateHasSendDocPromoImage(dossierId, infos.hasSendDocPromoImage,file);
			setCurrentStep(EtapeDemandeVersement.ValidationArtiste);
			setIsLoading(false);
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			setIsLoading(false);
		}
	}

	const onValidate = () => {
		(async function () {
			setIsLoading(true);
			try {
				await versementClient.updateHasSendDocPromoImage(dossierId, infos.hasSendDocPromoImage,file);
				setCurrentStep(EtapeDemandeVersement.ValidationArtiste);
				setIsLoading(false);
			} catch(error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
				setIsLoading(false);
				return false;
			}
		})()
	}

	// const [file, setFile] = useState<DocumentDepenses>({
	// 	id: "",//depense.document?.id,
	// 	fileName: "" // depense.document?.fileName
	// });
	const [errorValidation, setErrorValidation] = useState('')
	
	
	const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
	
		if (value.target.files.length !== 0) {
            if (value.target.files[0].name !== "") {
				setFile(value.target.files[0]);            
			}
			else {
				setFile(undefined);
            }
		}
	}

		const deleteFile = (idFile:string) => {
			setFile(undefined);
		}

	return (
		<form>
			<div className="creationDossier__header">
				<h3 className="title--dark">{t("creationVersement.infosDossier.title")}</h3>
			</div>
			{infos
				? <>
				<section className="creationDossier__item">
					<div className="creationDossier__row">
						<InputReadOnly
							label={t("creationVersement.infosDossier.nom")}
							content={ infos?.nom ||'-'}
						/>
						<InputReadOnly
							label={t("creationVersement.infosDossier.categorie")}
							content={infos?.categorieDossier ||'-'}
						/>
					</div>
					<div className="creationDossier__row">
						<InputReadOnly
							label={t("creationVersement.infosDossier.responsable")}
							content={infos?.responsableFullName ||'-'}
						/>
						<InputReadOnly
							label={t("creationVersement.infosDossier.mail")}
							content={infos?.mailResponsable ||'-'}
						/>
					</div>
					<div className="creationDossier__row">
						<InputReadOnly
							label={t("creationVersement.infosDossier.telephone")}
							content={infos?.telResponsable ? formatPhone(infos.telResponsable) : '-'}
						/>
						<InputReadOnly
							label={t("creationVersement.infosDossier.dateCom")}
							content={`
								${infos && infos.commissionsDebut ? moment(infos.commissionsDebut).format('DD/MM/YYYY') : '-'}
								${infos && infos.commissionFin ?  ` ${t("creationVersement.infosDossier.dateProjTo")} ${moment(infos.commissionFin).format('DD/MM/YYYY')}` : ''}
							`}
						/>
					</div>
					<div className="creationDossier__row">
						<InputReadOnly
							label={t("creationVersement.infosDossier.dateProj")}
							content={`
								${infos && infos.dateProjetDebut ? moment(infos.dateProjetDebut).format('DD/MM/YYYY') : '-'}
								${infos && infos.dateProjetFin ?  ` ${t("creationVersement.infosDossier.dateProjTo")} ${moment(infos.dateProjetFin).format('DD/MM/YYYY')}` : ''}
							`}
						/>
						<InputReadOnly
							label={t("creationVersement.infosDossier.base")}
							content={infos.baseCalcul ? `${formatNumber(infos.baseCalcul)} €` : '-'}
						/>
					</div>
					<div className="creationDossier__row">
						<InputReadOnly
							label={t("creationVersement.infosDossier.montantDemande")}
							content={infos.montantDemande ? `${formatNumber(infos.montantDemande)} €` : '-'}
						/>
						<InputReadOnly
							label={t("creationVersement.infosDossier.montantAttribue")}
							content={infos.montantAttribue ? `${formatNumber(infos.montantAttribue)} €` : '-'}
						/>
					</div>
					<div className="creationDossier__row">
						<InputReadOnly
							label={t("creationVersement.infosDossier.montantPerçu")}
							content={`${formatNumber(infos.montantPercu)} €`}
						/>
						<InputReadOnly
							label={t("creationVersement.infosDossier.dateSoumission")}
							content={infos.dateSoumission ? moment(infos.dateSoumission).format('DD/MM/YYYY') : '-'}
						/>
					</div>
				</section>
				{infos?.isApi && <>
					<div className="creationDossier__checkbox">
						<InputRadioYesNo
							name="hasSendDocPromoImage"
							label={t("creationVersement.infosDossier.checkBoxLabel")}
							onChange={(value: boolean) => setInfos({...infos, hasSendDocPromoImage: value})}
							value={infos.hasSendDocPromoImage}
							radioLabels={[t("common.yes"), t("common.no")]}
						/>
						<ModalValidateCancel
							isOpen={isValidateModalOpen}
							onCancel={() => { setIsValidateModalOpen(false) }}
							onValidate={sendValidate}
							validateButtonLabel={t('common.validate')}
							isLoading={isLoading}
						>
							<div className="modal__item">
								<p>{t('creationVersement.infosDossier.warning')}</p>
							</div>
						</ModalValidateCancel>
					</div>

					<div className="input">
						<label className="label--withPadding">Télécharger la captation de l'API</label>
						{!file?.name &&
							<div className="inputFile__buttons" style={{width: "30%"}}>
								<label htmlFor="uploadDocument">Télécharger la captation de l'API (max 0,99 Go soit 1 073 721 344 octets)</label>
								<input
									type="file"
									id="uploadDocument"
									onChange={(value) => handleFileChange(value)}
								/>
							</div>
						}
						{file?.name &&
							<div className="inputFile__infos--file">
								<span className="inputFile__infos--fileName">
									{file.name}
									<i className="far fa-trash-alt" onClick={() => deleteFile("")}></i>
								</span>
							</div>
						}
					</div>

				</>
			}
			</>
			: <div className="versementsLoader"><Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader></div>
		}
		</form>
	)
});

export default InformationsDossier;
